import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  CheckboxProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  OutlindedFieldContainer,
  StyledButton,
  FormText,
  FileContainer,
  DeleteIcon,
  StyledFormGroup,
  StyledTextField,
  StyledGridContainer,
  StyledOtherContainer,
  StyledCheckBoxContainer,
  SectionTitle,
  CheckBoxTitle,
  StyledAlert,
} from "./styles";

import { FormTitle } from "../FormTitle";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { colors } from "../../styles/variables";
import { getFiles } from "../toBase64File";
import { callEmailAPI } from "../emailSend";

const GreenCheckbox = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: "#158da7",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    top: {
      color: `${colors.dentBlue}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);

const Referral = () => {
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [isOtherClinical, setIsOtherClinical] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFileSize, setOpenFileSize] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const { register, handleSubmit, errors, control } = useForm();
  const classes = useStyles();

  const mobileProps = {
    maxLength: 10,
  };
  const nameProps = {
    maxLength: 40,
  };
  const addressProps = {
    maxLength: 100,
  };
  const postCodeProps = {
    maxLength: 4,
  };
  const messageProps = {
    maxLength: 1000,
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    data.requestPage = "Online Referral Form";
    let files;
    await getFiles(uploadFile).then((customJsonFile) => {
      files = customJsonFile;
    });
    data.files = files;
    const maxFileSize = 4194304;
    let totalFileSize = 0;
    files.map((item) => (totalFileSize = totalFileSize + item.fileSize));

    if (totalFileSize < maxFileSize) {
      await callEmailAPI(data).then(({ isSubmitting, response }) => {
        setSubmitting(isSubmitting);
        response.ok ? setOpenSucceed(true) : setOpenError(true);
      });
    } else {
      setOpenFileSize(true);
      setSubmitting(false);
      setUploadFile([]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone();

  const deleteFile = (name) => {
    const index = uploadFile.findIndex((file) => file.name === name);

    uploadFile.splice(index, 1);
    setUploadFile([...uploadFile]);
  };

  const changingUploadFilesCheck = () => {
    setIsUploadFiles(!isUploadFiles);

    if (isUploadFiles) {
      setUploadFile([]);
    }
  };

  useEffect(() => {
    setUploadFile([...uploadFile, ...acceptedFiles]);
  }, [acceptedFiles]);
  return (
    <>
      <Breadcrumb pageTitle="Online Referral">
        <BreadcrumbItem Link="/referral" Text="Online Referral" />
      </Breadcrumb>
      <PageContainer>
        <FormTitle>Online Referral Form</FormTitle>

        <OutlindedFieldContainer>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Patient information</SectionTitle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="First name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="firstNamePatient"
                  error={errors.firstNamePatient ? true : false}
                />
                <span className="error-message">
                  {errors.firstNamePatient && "First name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Last name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="lastNamePatient"
                  error={errors.lastNamePatient ? true : false}
                />
                <span className="error-message">
                  {errors.lastNamePatient && "Last name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  id="dob"
                  label="Date of birth"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  name="dobPatient"
                  error={errors.dobPatient ? true : false}
                />
                <span className="error-message">
                  {errors.dobPatient && "Date of birth is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Mobile"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: /[0][4][0-9]{8}/,
                  })}
                  inputProps={mobileProps}
                  name="mobilePatient"
                  error={errors.mobilePatient ? true : false}
                />
                <span className="error-message">
                  {errors.mobilePatient &&
                    "10 digits mobile number is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  name="emailPatient"
                  error={errors.emailPatient ? true : false}
                />
                <span className="error-message">
                  {errors.emailPatient && "Email address is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Address"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 100 })}
                  inputProps={addressProps}
                  name="addressPatient"
                  error={errors.addressPatient ? true : false}
                />
                <span className="error-message">
                  {errors.addressPatient && "Address is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="City / Suburb"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="suburbPatient"
                  error={errors.suburbPatient ? true : false}
                />
                <span className="error-message">
                  {errors.suburbPatient && "City / Suburb is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={Boolean(errors.statePatient)}
                >
                  <InputLabel>State</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="" disabled>
                          Please select
                        </MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                      </Select>
                    }
                    name="statePatient"
                    rules={{ required: "State is required" }}
                    control={control}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.statePatient && errors.statePatient.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <StyledTextField
                  label="Postcode"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    pattern: /[0-9]{4}/,
                  })}
                  inputProps={postCodeProps}
                  name="postcodePatient"
                  error={errors.postcodePatient ? true : false}
                />
                <span className="error-message">
                  {errors.postcodePatient && "Postcode is required"}
                </span>
              </Grid>
              <StyledCheckBoxContainer>
                <CheckBoxTitle>
                  <span>Referral for</span>
                </CheckBoxTitle>
                <StyledFormGroup row>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="missingToothOptions"
                        />
                      }
                      label="Missing Tooth Options"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="crownBridge"
                        />
                      }
                      label="Crown & Bridge"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="implants"
                        />
                      }
                      label="Implants"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="dentures"
                        />
                      }
                      label="Dentures"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="aestheticConcerns"
                        />
                      }
                      label="Aesthetic Concerns"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="oralRehabilitation"
                        />
                      }
                      label="Oral Rehabilitation"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="veneers"
                        />
                      }
                      label="Veneers / Porcelain Restorations"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="wear"
                        />
                      }
                      label="Wear / Abrasion / Erosion"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="implantRehabilitation"
                        />
                      }
                      label="Implant Rehabilitation"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="removePost"
                        />
                      }
                      label="Remove Post / Crown / Bridge"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="tmjAssessment"
                        />
                      }
                      label="TMJ Assessment & Treatment"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="smileImprovement"
                        />
                      }
                      label="Smile Improvement"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="discuss"
                        />
                      }
                      label="Please Discuss Compromise Treatment Options"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={isOtherClinical}
                          onChange={() => setIsOtherClinical(!isOtherClinical)}
                          inputRef={register({ required: false })}
                          name="other"
                        />
                      }
                      label="Other"
                    />
                  </Grid>
                </StyledFormGroup>
                <StyledOtherContainer hidden={!isOtherClinical}>
                  <StyledTextField
                    label="Your Clinical Notes if Required"
                    variant="outlined"
                    multiline
                    rows={6}
                    type="text"
                    inputRef={register({
                      required: isOtherClinical,
                      maxLength: 1000,
                    })}
                    inputProps={messageProps}
                    name="otherComment"
                    error={errors.otherComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.otherComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
              </StyledCheckBoxContainer>
              <StyledCheckBoxContainer>
                <CheckBoxTitle>
                  <span>Imaging</span>
                </CheckBoxTitle>
                <StyledFormGroup row>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={isUploadFiles}
                          onChange={() => changingUploadFilesCheck()}
                          inputRef={register({ required: false })}
                          name="uploaded"
                        />
                      }
                      label="Uploaded"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="withPatient"
                        />
                      }
                      label="With patient"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="emailed"
                        />
                      }
                      label="Emailed"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="posted"
                        />
                      }
                      label="Posted"
                    />
                  </Grid>
                </StyledFormGroup>
              </StyledCheckBoxContainer>
              <Grid item xs={12}>
                <div hidden={!isUploadFiles}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FileContainer
                        {...getRootProps({
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        })}
                      >
                        <FormText>Drop files here or click to upload.</FormText>
                        <input {...getInputProps()} />
                      </FileContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List dense={false}>
                        {uploadFile.map((file) => (
                          <ListItem key={file.path}>
                            <ListItemText primary={file.name} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteFile(file.name)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </StyledGridContainer>

            <StyledGridContainer container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>Doctor information</SectionTitle>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Referred by DR"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="drName"
                  error={errors.drName ? true : false}
                />
                <span className="error-message">
                  {errors.drName && "Please provide doctor's name"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  label="Provider Number"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="providerNumber"
                  error={errors.providerNumber ? true : false}
                />
                <span className="error-message">
                  {errors.providerNumber && "Provider Number is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTextField
                  id="date"
                  label="Referral date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  name="referralDate"
                  error={errors.referralDate ? true : false}
                />
                <span className="error-message">
                  {errors.referralDate && "Referral date is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  label="Address"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 100 })}
                  inputProps={addressProps}
                  name="addressDr"
                  error={errors.addressDr ? true : false}
                />
                <span className="error-message">
                  {errors.addressDr && "Address is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="City / Suburb"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="suburbDr"
                  error={errors.suburbDr ? true : false}
                />
                <span className="error-message">
                  {errors.suburbDr && "City / Suburb is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={Boolean(errors.statePatient)}
                >
                  <InputLabel>State</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="" disabled>
                          Please select
                        </MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                      </Select>
                    }
                    name="stateDr"
                    rules={{ required: "State is required" }}
                    control={control}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.stateDr && errors.stateDr.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <StyledTextField
                  label="Postcode"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    pattern: /[0-9]{4}/,
                  })}
                  inputProps={postCodeProps}
                  name="postcodeDr"
                  error={errors.postcodeDr ? true : false}
                />
                <span className="error-message">
                  {errors.postcodeDr && "Postcode is required"}
                </span>
              </Grid>
            </StyledGridContainer>

            <StyledFormGroup row>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    inputRef={register({ required: false })}
                    name="isAppointment"
                  />
                }
                label="Request appointment"
              />
            </StyledFormGroup>

            <Collapse in={openSucceed}>
              <StyledAlert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSucceed(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Email sent successfully
              </StyledAlert>
            </Collapse>
            <Collapse in={openError}>
              <StyledAlert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error occurred. Please contact us.
              </StyledAlert>
            </Collapse>
            <Collapse in={openFileSize}>
              <StyledAlert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenFileSize(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Total file size should be less than 4MB. Please send files
                through an email.
              </StyledAlert>
            </Collapse>

            <Grid container spacing={3} justify="center">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </OutlindedFieldContainer>
      </PageContainer>
    </>
  );
};

export default Referral;
