import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Referral from "../components/referral";

const ReferralPage = () => (
  <Layout>
    <SEO title="Dental Service for Referral form" />
    <Referral />
  </Layout>
);

export default ReferralPage;
